@font-face {
  font-family: Roboto-Bold;
  src: url(src/assets/font/Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-Light;
  src: url(src/assets/font/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(src/assets/font/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(src/assets/font/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto-Thin;
  src: url(src/assets/font/Roboto-Thin.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(src/assets/font/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-Light;
  src: url(src/assets/font/Inter-Light.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(src/assets/font/Inter-Regular.ttf);
}
* {
  font-family: Inter-Regular, sans-serif;
}
.main {
  height: 100%;
  position: relative;
   margin-left: 200px;
   /* padding-top: 80px; */
   padding-top: 10px;
  z-index: 0;
  background-color: rgb(247, 247, 247);
}
.main-content{
  padding: 10px;
}
::ng-deep .card{
  background-color: #fff !important;
  border: solid 1px #e1e0e0 !important;
  border-radius: 5px;
}
@import "~@flaticon/flaticon-uicons/css/all/all";
a {
  text-decoration: none;
  color: inherit; /* or any other color value you want */
}
.dt-row{
  min-height: 80px !important;
  max-height: 480px !important;
  overflow-y: scroll !important;
}
.dataTables_filter{
  margin-bottom: 10px !important;
}

/*Spinner*/
.spinner-square {
  display: flex;
  flex-direction: row;
  width: 20px;
  height: 20px;
}

.spinner-square > .square {
  width: 4px;
  height: 15px;
  margin: auto auto;
  border-radius: 4px;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
}

@keyframes square-anim {
  0% {
      height: 15px;
      background-color: var(--primary);
  }
  20% {
      height: 15px;
  }
  40% {
      height: 20px;
      background-color: var(--primary);
  }
  80% {
      height: 15px;
  }
  100% {
      height: 15px;
      background-color: var(--primary);
  }
}
/* Notifications*/
.toast-success{
  background-color: white !important;
  color: var(--c1) !important;
  border-radius: 4px !important;
  animation: slideIn 0.3s ease-in-out;
  font-size: 80%;
}
.toast-info{
  background-color: white !important;
  color: var(--c1) !important;
  border-radius: 4px !important;
  animation: slideIn 0.3s ease-in-out;
  font-size: 80%;
}
.toast-error {
  background-color: white !important;
  color: red !important;
  border-radius: 4px !important;
  animation: slideIn 0.3s ease-in-out;
  font-size: 80%;
}
.toast-warning {
  background-color: white !important;
  color: rgb(255, 162, 0) !important;
  border-radius: 4px !important;
  animation: slideIn 0.3s ease-in-out;
  font-size: 80%;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
/*Buttons*/
.btn{
  font-size: 80%;
}
.btn-primary{
  background-color: var(--primary)!important;
  border-color: white !important;
  color: #ffffff;
  display: flex;
  align-items: center;
}
/* .btn-primary:hover{
  background-color: var(--c3) !important;
} */
.btn-secondary{
  background-color: var(--c3);
  color: #fff;
}
.btn-confirm{
  background-color: var(--g8)!important;
  border-color: white !important;
  color: #ffffff !important;
  text-align: center;
  margin: 0 1%;
}
.btn-light{
  background-color:#fff ;
  color: var(--g3);
  text-align: center;
  margin: 0 1%;
}
.btn-light:hover{
  background-color:#f8f8f8 ;
}

/*checkbox*/
.form-check-input:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}
:root{
  --primary:#32ab85;
  --success:#1ea47a;
  --blue-primary:#16aaff;
  --blue-secondary:aliceblue;
  --yellow:#f7b924;
  --danger:rgb(255, 101, 101);

  --c1:#131716;
  --c2:#1F3425;
  --c3:#4C6869;
  --c4:#889D8E;
  --c5:#e8ebeb;

  --g1:#1a5848;
  --g2:#1ea47a;
  --g3:#05161A;
  --g4:#072E33;
  --g5:#0C7025;
  --g6:#0F9690;
  --g7:#6DA5C0;
  --g8:#294D61;

  --gray:gray;


}
/*Cursor*/
.pointer{
  cursor: pointer;
}
/*colors*/
.x-cl-primary{
  color: var(--c1);
}
.x-cl-success{
  color: var(--success);
}
.x-cl-danger{
  color: var(--danger);
}
.x-cl-bp{
  color: var(--c2);
}
.x-cl-white{
  color: white;
}
/*font sizes*/
.x-fs-sm{
  font-size: small;
}
.x-fs-lg{
  font-size:larger
}
/*background*/
.x-bg-cl-primary{
  background-color: var(--c1);
}
.x-bg-cl-bs{
  background-color: var(--c5);
}
.x-bg-cl-bp{
  background-color: var(--c2);
}
.x-bg-cl-white{
  background-color: #fff;
}
/*shadow*/
.x-shadow{
  box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
}


/*status badges*/
.badge-primary{
  background-color: var(--success);
  color: white;
  border-radius: 4px;
  padding: 2px 10px;
  font-size: 12px;
}
.badge-danger{
  background-color: var(--danger);
  color: white;
  border-radius: 4px;
  padding: 2px 10px;
  font-size: 12px;
}



/*old code*/
html,
body {
  margin: 0 !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgb(247, 247, 247);
}

.active > .page-link,
.page-link.active {
  background-color: #32ab85 !important;
  border-color: #32ab85 !important;
  color: rgb(255, 255, 255) !important;
}

.page-link {
  color: #32ab85 !important;
}

div.dt-buttons > .dt-button,
div.dt-buttons > div.dt-button-split .dt-button {
  background: none !important;
}

div.dt-buttons > .dt-button {
  background-color: #1a5848 !important;
  border-color: #1a5848 !important;
  color: white !important;
}

.mat-primary .mat-pseudo-checkbox-checked {
  background-color: #32ab85 !important;
}

.green-snackbar {
  color: #fff !important;
  font-family: Inter-Bold, sans-serif;
  font-weight: bold;
  background: #319f43 !important;
}

.red-snackbar {
  color: #fff !important;
  font-family: Inter-Bold, sans-serif;
  font-weight: bold;
  background: #fe0000;
}

.mat-simple-snackbar {
  justify-content: center !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #32ab85;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #caebe1;
}

mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
}

.mat-dialog-container {
  overflow: hidden !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #32ab85 !important;
}

.mat-tab-header {
  width: 15% !important;
}

.mat-tab-label {
  filter: invert(51%) sepia(12%) saturate(1977%) hue-rotate(110deg)
    brightness(107%) contrast(91%) !important;
}

.mat-tab-label.mat-tab-label-active {
  filter: invert(51%) sepia(12%) saturate(1977%) hue-rotate(110deg)
    brightness(107%) contrast(91%) !important;
}

@media (min-width: 360px) and (max-width: 850px) {
  .mat-dialog-container {
    border-radius: 18px !important;
    padding: 10px !important;
  }
}
